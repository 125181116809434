import { WtcReason } from "@baloise-cfa/tsclient/mob";
import { graphql, navigate } from "gatsby";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";

import {
    ContactFormValue,
    Footer,
    Loading,
    WtcContactMe,
} from "@modules/shared/components";
import { Application, LeadInfoKey } from "@modules/shared/enums";
import { modelDTO, validateBusinessRules } from "@modules/shared/helpers";
import {
    useCreateLead,
    useResetFlow,
    useSitePaths,
    useWtc,
} from "@modules/shared/hooks";
import { AppState, appState } from "@modules/shared/state";
import { PageProps } from "@modules/shared/types";
import { SimulatorLayout } from "@modules/simulator";

const WTCContactMePage: React.FC<PageProps<object, { noPrices?: boolean }>> = ({
    pageContext,
    location,
}) => {
    const { foundPage: page, ownPageObjects: allPages, language } = pageContext;
    const [appData, setAppData] = useRecoilState<AppState>(appState);
    const createLead = useCreateLead();
    const { businessRules } = useWtc(allPages);
    const { getSitePath } = useSitePaths();
    const { resetFlow } = useResetFlow(
        allPages,
        language,
        Application.Simulator,
    );
    const [loading, setLoading] = useState<boolean>(false);
    const wtcInfo = validateBusinessRules(businessRules, appData).wtcInfo;
    const reasons = wtcInfo.wtcReason;
    const { t } = useTranslation();

    const initialFormValues: ContactFormValue = useMemo(
        () => ({
            ...appData.WTCContactMe,
        }),
        [appData],
    );

    const handleSubmit = async () => {
        setLoading(true);

        const redirect = allPages.find((p) => p.name === page?.navigation?.next)
            ?.paths[language];
        const errorRedirect = getSitePath("500");

        if (location?.state?.noPrices) {
            wtcInfo.wtcReason?.push(WtcReason.NoPriceAvailable);
        }

        const dto = modelDTO(
            appData,
            language,
            LeadInfoKey.WTCContactMe,
            wtcInfo,
            Application.Simulator,
        );

        const { success } = await createLead(dto, Application.Simulator);

        if (success) {
            resetFlow(false);
            navigate(redirect ?? "/");
        } else {
            navigate(errorRedirect);
        }

        setLoading(false);
    };

    const handleContactMeChange = (
        contactFormValue: ContactFormValue,
    ): void => {
        setAppData({
            ...appData,
            WTCContactMe: contactFormValue,
        });
    };

    return (
        <>
            {loading && <Loading />}
            <SimulatorLayout
                title={t("all.wtccontactme.title")}
                page={page}
                language={language}
                allPages={allPages}
            >
                <main className="container is-compact mt-large flex flex-direction-column">
                    <div className="flex-1">
                        <WtcContactMe
                            wtcReasons={reasons}
                            application={Application.Simulator}
                            professionalUsage={
                                !!appData.BeforeWeStart?.professionalUsage
                            }
                            initialValue={initialFormValues}
                            loading={loading}
                            onSubmit={handleSubmit}
                            onChange={handleContactMeChange}
                        />
                    </div>
                    <Footer />
                </main>
            </SimulatorLayout>
        </>
    );
};

export default WTCContactMePage;

export const pageQuery = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
